



















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import AdjustmentTextInput from '../../components/AdjustmentTextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import {
  EventBusConstants,
  HEADER_REQUEST_TOPUP_HISTORY,
  REQUEST_TOP_SALDO_PAGINATION,
  MODAL_CONFIRM_APROVING_TOPUP,
  MODAL_SUCCESS_APPROVE_TOPUP,
  MODAL_SUCCESS_REJECT_TOPUP,
  MODAL_REJECT_REQUEST_TOPUP,
  Utils, EnumBalanceID,
} from '@/app/infrastructures/misc'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import ModalReject from '../../components/AdjustmentModals/ModalReject/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller, {
  RequestTopupListParams,
} from '@/app/ui/controllers/RequestTopupSaldoController'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import ModalConfirm from '../../components/AdjustmentModals/ModalConfirm/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalDetail from '../../components/AdjustmentModals/ModalDetail/index.vue'
import { EnumTypeBalanceStatus } from '@/app/infrastructures/misc/Constants/adjustment'
import { Dictionary } from 'vue-router/types/router'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import * as constantsData from '@/app/infrastructures/misc/Constants/adjustment'

export interface modalInterface {
  visible: boolean
  title: string
  subtitle: string
}

@Component({
  components: {
    RadioButton,
    AdjustmentTextInput,
    Button,
    DataTableV2,
    PaginationNav,
    ModalReject,
    LoadingOverlay,
    ModalConfirm,
    Modal,
    ModalDetail,
  },
})
export default class ApprovingTopupDetailPage extends Vue {
  controller = controller
  constantsData = constantsData
  MODAL_CONFIRM_APROVING_TOPUP = MODAL_CONFIRM_APROVING_TOPUP
  MODAL_SUCCESS_APPROVE_TOPUP = MODAL_SUCCESS_APPROVE_TOPUP
  MODAL_SUCCESS_REJECT_TOPUP = MODAL_SUCCESS_REJECT_TOPUP
  MODAL_REJECT_REQUEST_TOPUP = MODAL_REJECT_REQUEST_TOPUP
  modalReject = false
  modalConfirm = false
  modalSuccess: modalInterface = {
    visible: false,
    title: '',
    subtitle: '',
  }
  modalDetail = false
  isTableShow = false
  headers = HEADER_REQUEST_TOPUP_HISTORY
  parameters: RequestTopupListParams = {
    page: 1,
    perPage: REQUEST_TOP_SALDO_PAGINATION,
    sortBy: 'NEWEST',
    filterStatus: 'REQUESTED',
    customerId: 0,
  }
  form = {
    saldo: NaN,
    balanceTypeAdjustment: constantsData.EnumBalanceID.TOP_UP,
    note: '',
  }
  currentNotes = ''
  isSuperAdmin = Utils.isSuperAdmin()
  currencySaldoSymbol = ''

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page || 1),
        perPage: REQUEST_TOP_SALDO_PAGINATION,
        sortBy: 'NEWEST',
        filterStatus: 'REQUESTED',
        customerId: 0,
      }
    }

    this.fetchHistory()
    this.fetchDetail()
  }

  onSuccess(): void {
    this.modalSuccess = {
      visible: false,
      title: '',
      subtitle: '',
    }
    this.$router.push({ name: 'ApprovingTopUpSaldo' })
  }

  get params(): RequestTopupListParams {
    return {
      ...this.parameters,
      customerId: Number(this.$route.params.id.split('-')[0]),
    }
  }

  get dataHistoryUser(): Array<DataObject[]> {
    return this.controller.listRequestTopupHistory.map(item => {
      return [
        {
          value: this.formatDate(item?.createdAt || ''),
          customStyle: { minWidth: '220px', maxWidth: '220px' },
        },
        {
          value: `${
            this.getBalanceTypeAdjustment(item.balanceTypeID || '')
              .symbol
          }${Utils.toRupiah(Math.abs(item?.totalSaldoRequest || 0))}`,
          customStyle: { minWidth: '125px', maxWidth: '125px' },
        },
        {
          value: Utils.toRupiah(item?.currentBalance || 0),
          customStyle: { minWidth: '140px', maxWidth: '140px' },
        },
        {
          value: item.requestor || '-',
          customStyle: { minWidth: '140px', maxWidth: '140px' },
        },
        {
          value: item.approval || '-',
          customStyle: { minWidth: '140px', maxWidth: '140px' },
        },
        {
          value: item.note,
          customStyle: { minWidth: '155px', maxWidth: '155px' },
        },
        {
          value: item.balanceStatusId || '-',
          customStyle: { minWidth: '110px', maxWidth: '110px' },
        },
      ]
    })
  }

  private formatDate(date: string): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(date, 'DD MMMM YYYY HH:mm Z')
    )
  }

  get phoneNumber(): string {
    return this.controller.phoneNumber
      ? Utils.countryIndonesiaPhoneNumber(this.controller.phoneNumber, true)
      : ''
  }

  private handleEmitReject(notes: string): void {
    controller.approvingTopupSaldo({
      balanceId: Number(this.$route.params.id.split('-')[1]),
      balanceStatus: EnumTypeBalanceStatus.REJECTED,
      reasonNote: notes,
    })
  }

  private handleEmitApprove(): void {
    controller.approvingTopupSaldo({
      balanceId: Number(this.$route.params.id.split('-')[1]),
      balanceStatus: EnumTypeBalanceStatus.APPROVED,
      reasonNote: '',
    })
  }

  private toggleTableShow(): void {
    this.isTableShow = !this.isTableShow
  }

  private fetchHistory(): void {
    this.controller.getHistoryRequestTop(this.params)
  }

  private fetchDetail(): void {
    this.controller.getDetailTopupForm(
      Number(this.$route.params.id.split('-')[1])
    )
  }

  private seeNotes(index: number): void {
    this.modalDetail = true
    this.currentNotes = <string>this.dataHistoryUser[index][5].value
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    if (!this.isSuperAdmin) {
      this.$router.push({ name: 'ApprovingTopUpSaldo' })
    }
    this.$router
      .replace({
        query: {
          ...val,
        },
      })
      .catch(() => false)
  }

  private getBalanceTypeAdjustment(
    balanceTypeAdjustment: string
  ): {
    symbol: string
    type: constantsData.EnumBalanceID
  } {
    let data = { symbol: '+', type: constantsData.EnumBalanceID.TOP_UP }
    if (
      balanceTypeAdjustment.toUpperCase() ===
      constantsData.EnumBalanceID.DEDUCT
    ) {
      data = { symbol: '-', type: constantsData.EnumBalanceID.DEDUCT }
    }
    return data
  }

  @Watch('controller.detailTopupForm', { deep: true })
  onChangedForm(): void {
    const balanceTypeAdjustment = this.getBalanceTypeAdjustment(
      controller.detailTopupForm?.balanceTypeId || ''
    )
    this.form = {
      saldo: this.controller.detailTopupForm?.amountRequested || 0,
      note: this.controller.detailTopupForm?.note || '',
      balanceTypeAdjustment: balanceTypeAdjustment.type,
    }
  }

  @Watch('controller.statusApproveReject', { deep: true })
  onStatusApproveRejectChanged(status: string): void {
    if (status !== '') {
      switch (status) {
        case EventBusConstants.APPROVE_APPROVING_TOPUP_SUCCESS:
          this.modalConfirm = false
          this.modalSuccess = {
            visible: true,
            title: MODAL_SUCCESS_APPROVE_TOPUP.title,
            subtitle: MODAL_SUCCESS_APPROVE_TOPUP.subtitle,
          }
          break
        case EventBusConstants.REJECT_APPROVING_TOPUP_SUCCESS:
          this.modalReject = false
          this.modalSuccess = {
            visible: true,
            title: MODAL_SUCCESS_REJECT_TOPUP.title,
            subtitle: MODAL_SUCCESS_REJECT_TOPUP.subtitle,
          }
          break
        case EventBusConstants.APPROVE_APPROVING_TOPUP_FAILED:
          this.modalConfirm = false
          break
        case EventBusConstants.REJECT_APPROVING_TOPUP_FAILED:
          this.modalConfirm = false
          break
      }
      controller.setStatusApproveReject('')
    }
  }

  beforeDestroy(): void {
    controller.setStatusApproveReject('')
  }
}
